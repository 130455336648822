import React from "react";

import cls from "./StatsComp.module.css";

import leftLeaf from "../../assets/left.svg";
import rightLeaf from "../../assets/right.svg";

const StatsComp = () => {
  return (
    <>
      <div className="container">
        <section className={cls.stats}>
          <h1 className={cls.heading}>We handle everything for you!</h1>
          <p className={cls.subheading}>
            Welcome to Deepam Thermoplastic Private Limited, your premier
            destination for high-quality thermoplastic road paint. As industry
            leaders in manufacturing, we take pride in delivering innovative
            solutions that enhance road safety and visibility. Explore our range
            of top-notch products designed for durability and superior
            performance. Drive with confidence – choose Deepam Thermoplastics
            for roads that reflect excellence.
          </p>

          <div className={cls.card_container}>
            <div className={cls.card}>
              <div className={cls.card_number}>20</div>
              <div className={cls.card_title}>Years</div>
              <div className={cls.card_subtitle}>Experience</div>
              <p>Delivering solutions for your roads.</p>
            </div>

            <img
              src={leftLeaf}
              className={cls.leafs}
              alt="Decoration"
              draggable={false}
            />

            <div className={cls.card}>
              <div className={cls.card_number}>25</div>
              <div className={cls.card_title}>Specialists</div>
              <div className={cls.card_subtitle}>In Road Marking</div>
              <p>
                We gather the best talent, creating the best specialist team.
              </p>
            </div>

            <img
              src={rightLeaf}
              className={cls.leafs}
              alt="Decoration"
              draggable={false}
              style={{
                webkitTransform: "scaleX(-1)",
                transform: "scaleX(-1)",
              }}
            />

            <div className={cls.card}>
              <div className={cls.card_number}>6</div>
              <div className={cls.card_title}>States</div>
              <div className={cls.card_subtitle}>& 3 Union Territories</div>
              <p>Best road marking works for your inspiration.</p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default StatsComp;
