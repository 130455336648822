import React from "react";

import Cover from "../Cover/Cover";
import AboutUsCard from "../AboutUsCard/AboutUsCard";
import FooterComp from "../FooterComp/FooterComp";

import gal1 from "../../assets/gal-1.png";
import gal2 from "../../assets/gal-2.png";
import gal3 from "../../assets/gal-3.png";
import gal4 from "../../assets/gal-4.png";
import gal5 from "../../assets/gal-5.png";
import gal6 from "../../assets/gal-6.png";
import gal7 from "../../assets/gal-7.png";
import gal8 from "../../assets/gal-8.png";
import gal9 from "../../assets/gal-9.png";
import gal10 from "../../assets/gal-10.png";
import gal11 from "../../assets/gal-11.png";
import gal12 from "../../assets/gal-12.png";
import gal13 from "../../assets/gal-13.png";
import gal14 from "../../assets/gal-14.png";

import cls from "./GalleryComp.module.css";

const GalleryComp = () => {
  const data = [
    {
      id: 1,
      src: gal1,
    },
    {
      id: 2,
      src: gal2,
    },
    {
      id: 3,
      src: gal3,
    },
    {
      id: 4,
      src: gal4,
    },
    {
      id: 5,
      src: gal5,
    },
    {
      id: 6,
      src: gal6,
    },
    {
      id: 7,
      src: gal7,
    },
    {
      id: 8,
      src: gal8,
    },
    {
      id: 9,
      src: gal9,
    },
    {
      id: 10,
      src: gal10,
    },
    {
      id: 11,
      src: gal11,
    },
    {
      id: 12,
      src: gal12,
    },
    {
      id: 13,
      src: gal13,
    },
    {
      id: 14,
      src: gal14,
    },
  ];
  return (
    <>
      <Cover title={"Gallery"} />
      <div className={"container"}>
        <section className={cls.content}>
          <div className={cls.cardContainer}>
            {data.map((item) => {
              return <AboutUsCard key={item.id} src={item.src} heading={""} />;
            })}
          </div>
        </section>
        <FooterComp />
      </div>
    </>
  );
};

export default GalleryComp;
