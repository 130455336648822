import React from "react";

import Services from "../components/Services/Services";

const Service = () => {
  return (
    <>
      <Services />
    </>
  );
};

export default Service;
