import React from "react";

import LandingComp from "../components/LandingComp/LandingComp";
import StatsComp from "../components/StatsComp/StatsComp";
import MapComp from "../components/MapComp/MapComp";
import FooterComp from "../components/FooterComp/FooterComp";

const Landing = () => {
  return (
    <>
      <LandingComp />
      <StatsComp />
      <MapComp />
      <FooterComp />
    </>
  );
};

export default Landing;
