import React from "react";

import cls from "./MapComp.module.css";

import map from "../../assets/maps.png";

const MapComp = () => {
  return (
    <>
      <div className="container">
        <div className={cls.image_container}>
          <img src={map} alt="Location" className={cls.map} draggable={false} />
        </div>
      </div>
    </>
  );
};

export default MapComp;
