import React from "react";

import ContactUs from "../components/ContactUs/ContactUs";

const Contact = () => {
  return (
    <>
      <ContactUs />
    </>
  );
};

export default Contact;
