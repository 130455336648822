import React from "react";

import Button from "../UI/Button/Button";

import cls from "./FooterComp.module.css";

import logo from "../../assets/logo.png";

import { Link } from "react-router-dom";

const FooterComp = () => {
  return (
    <>
      <div className="container">
        <section className={cls.footer}>
          <img src={logo} alt="Logo" draggable={false} className={cls.logo} />

          <div className={cls.btn_container}>
            <Link className={cls.links} to={"/contact"}>
              <Button
                borderColor={"#4F709C"}
                backgroundColor={"transparent"}
                color={"#4F709C"}
                hoveredBorderColor={"#ffffff"}
                hoveredBackgroundColor={"#4F709C"}
                hoveredColor={"#ffffff"}
              >
                Contact us
              </Button>
            </Link>
          </div>

          <p className={cls.phone}>Call Us: +918072885484.</p>

          <p className={cls.phone}>Info@deepamthermoplastics.com</p>

          <p className={cls.address}>
            Deepam Thermoplastic Pvt. Ltd. No. 639/640, Palani street, Arch
            Anthoniyar nagar, Extension 2, vellanur, Avadi- 600 062
          </p>

          <div className={cls.copyright}>
            @{new Date().getFullYear()} Japan Grab technologies
          </div>
        </section>
      </div>
    </>
  );
};

export default FooterComp;
