import React from "react";

import Cover from "../Cover/Cover";
import FooterComp from "../FooterComp/FooterComp";

import cls from "./AboutUs.module.css";
import anna from "../../assets/banda.jpg";

const AboutUs = () => {
  return (
    <>
      <Cover title={"About Us"} />

      <div className="container">
        <section className={cls.content}>
          <div className={cls.partition}>
            <h1 className={cls.leftSide}>
              Welcome to Deepam Thermoplastics Private Limited
            </h1>
            <div className={cls.imageContainer}>
              <img src={anna} alt="Founder" className={cls.anna} />
            </div>
          </div>

          <div className={cls.contentItem}>
            <h2>About Our Managing Director - C P Senthilkumar</h2>
            <p>
              With a rich legacy spanning two decades in the thermoplastic paint
              laying and manufacturing field, C P Senthilkumar stands as the
              driving force behind Deepam Thermoplastics Private Limited. As the
              visionary Managing Director of our esteemed company, he brings a
              wealth of experience and expertise to the realm of road marking
              paint manufacturing.
            </p>
          </div>

          <div className={cls.contentItem}>
            <h2>Professional Journey: 20 Years of Excellence</h2>
            <p>
              Throughout his illustrious career, Mr. Senthilkumar has been a
              stalwart in the thermoplastic paint industry, showcasing an
              unwavering commitment to quality and innovation. His two decades
              of hands-on experience have not only honed his skills but have
              also played a pivotal role in shaping the trajectory of the
              company.
            </p>
          </div>

          <div className={cls.contentItem}>
            <h2>Leadership at Its Pinnacle</h2>
            <p>
              As the Managing Director, Mr. Senthilkumar leads with a passion
              for excellence and a relentless pursuit of cutting-edge solutions.
              His strategic vision has propelled Deepam Thermoplastics to the
              forefront of the road marking paint manufacturing sector. Under
              his guidance, the company has consistently delivered products that
              surpass industry standards, ensuring safer roads and lasting
              impressions.
            </p>
          </div>

          <div className={cls.contentItem}>
            <h2>Commitment to Quality and Sustainability</h2>
            <p>
              Deepam Thermoplastics, under Mr. Senthilkumar's leadership, places
              a premium on quality and sustainability. His commitment to
              environmentally conscious practices is reflected in the company's
              product range, which not only meets regulatory standards but also
              contributes to a greener and safer future.
            </p>
          </div>

          <div className={cls.contentItem}>
            <h2>Driving Innovation in Road Safety</h2>
            <p>
              C P Senthilkumar's passion for innovation extends beyond
              manufacturing processes. He envisions Deepam Thermoplastics as a
              catalyst for change in the road safety landscape. Through
              continuous research and development, the company strives to
              introduce advanced solutions that redefine industry benchmarks.
            </p>
          </div>

          <div className={cls.contentItem}>
            <h2>Join Us in Building Safer Roads</h2>
            <p>
              Deepam Thermoplastics Private Limited, guided by the dynamic
              leadership of C P Senthilkumar, invites you to join our journey
              towards creating safer and more sustainable road infrastructure.
              As we continue to innovate and set new standards, we remain
              committed to delivering excellence in every drop of paint.
            </p>
          </div>
        </section>
      </div>

      <FooterComp />
    </>
  );
};

export default AboutUs;
