import React from "react";

import Cover from "../Cover/Cover";
import FooterComp from "../FooterComp/FooterComp";

import cls from "./Services.module.css";

const Services = () => {
  const adv = [
    {
      id: 1,
      content: "High retro-reflective performance (Day and Night)",
    },
    {
      id: 2,
      content: "Fast drying",
    },
    {
      id: 3,
      content: "Excellent adhesion",
    },
    {
      id: 4,
      content: "Good flow resistance",
    },
    {
      id: 5,
      content: "Good skid resistance",
    },
    {
      id: 6,
      content: "Non-toxic and environment-friendly",
    },
    {
      id: 7,
      content: "Value for money with long-lasting results",
    },
  ];
  return (
    <>
      <Cover title={"Services"} />
      <div className="container">
        <section className={cls.content}>
          <div className={cls.contentItem}>
            <p>
              Deepam Thermoplastic Pvt. Ltd. specializes in the manufacturing of
              high-quality Thermoplastic Pavement/Road/Traffic Markings under
              the eMark brand. These markings are designed to provide superior
              visibility and high performance, ensuring safety with minimal
              maintenance requirements. Thermoplastic Pavement/ Road/ Traffic
              Markings are the preferred choice for road authorities due to
              their long-term durability, cost-effectiveness, and excellent
              retroreflectivity.
            </p>
          </div>

          <div className={cls.contentItem}>
            <h2>Hydrocarbon Thermoplastics</h2>
            <p>
              Under the eMark range, Hydrocarbon Thermoplastics are formulated
              using high-quality CS Aliphatic Hydrocarbon resins, offering
              excellent heat stability, adhesion, and ease of application. eMark
              Hydrocarbon is versatile and suitable for various applications,
              including intersections, long-lines, legends, and symbols on both
              asphalt and Portland Cement Concrete (PCC) roads. Available in
              granular powder form in 25 kgs packing, it provides a reliable
              solution for road markings.
            </p>
          </div>

          <div className={cls.contentItem}>
            <h2>Application Method and Techniques</h2>
            <p>
              Thermoplastic coatings are a popular choice for road markings,
              striking a balance between cost, performance, and longevity. These
              coatings, based on Hydrocarbons or Resin Esters, consist of
              homogeneous dry mixes of binder resins, plasticizers, glass beads,
              pigments, fillers, and additives. They offer advantages such as
              increased durability, retroreflectivity, adhesion, skid
              resistance, visibility, resistance to wear, and environmental
              friendliness.
            </p>
            <p>
              Specialized machines are used for the application of Thermoplastic
              Markings, pre-heated to 200°C before being fed into apparatus like
              screed boxes, extruders, or spray guns. Glass beads are applied
              immediately to embed into the hot material, providing initial
              retro-reflection, and as the marking wears off, beads mixed with
              the binder continue to ensure long-term reflectivity.
            </p>
          </div>

          <div className={cls.contentItem}>
            <h2>Manufacturing and Supply</h2>
            <p>
              Deepam Thermoplastic Pvt. Ltd. manufactures and supplies
              Thermoplastic materials that comply with various international
              standards, including BS 1261: Part 1:1989, AASHTO M-249, and MORTH
              Clause 803.4.2. The company offers different grades of
              thermoplastic materials for spray, screed, or extrusion
              applications, tailored to meet diverse climatic conditions
              worldwide. Available in white and yellow, custom colors are also
              manufactured on request.
            </p>
          </div>

          <div className={cls.contentItem}>
            <h2>Thermoplastic Road Marking Material</h2>
            <p>
              Thermoplastic road marking material is a formulation of
              plasticized resin, aggregate, pigment, and glass beads supplied in
              dry powder form. This environmentally friendly, solvent-free
              material quickly cools and sets upon application to the road
              surface. Deepam Thermoplastic's aliphatic chemistry ensures
              superior weathering resistance and color stability, while intermix
              glass beads contribute to long-lasting retroreflectivity, adhesion
              on asphalt roads, and wear resistance.
            </p>
          </div>

          <div className={cls.contentItem}>
            <h2>Advantages of Thermoplastic Road Marking Paint</h2>
          </div>
          <ul>
            {adv.map((item) => {
              return <li key={item.id}>{item.content}</li>;
            })}
          </ul>

          <div className={cls.contentItem}>
            <h2>Retro Reflectivity</h2>
            <p>
              Retro reflectivity is a crucial feature of Thermoplastic Road
              Markings. Glass beads embedded in the marking refract and reflect
              light, providing optimal visibility. Proper embedment and
              roundness of beads contribute to maximum retro reflection,
              enhancing nighttime visibility and overall safety.
            </p>
            <p>
              Deepam Thermoplastic Pvt. Ltd.'s drop-on glass beads comply with
              BS 6088/1981 Type - 2 and Cl. 803.4.3.3 of MOST specifications.
              The recommended particle size distribution ensures effective
              retroreflectivity and longevity of the beads on the road surface.
            </p>
          </div>
        </section>
      </div>
      <FooterComp />
    </>
  );
};

export default Services;
