import React from "react";

import GalleryComp from "../components/GalleryComp/GalleryComp";

const Gallery = () => {
  return (
    <>
      <GalleryComp />
    </>
  );
};

export default Gallery;
