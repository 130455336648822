import React from "react";

import Header from "../Header/Header";

import cls from "./Cover.module.css";

import bg from "../../assets/about__background.png";

const Cover = ({ title }) => {
  return (
    <>
      <section className={cls.bgContainer}>
        <img
          src={bg}
          alt="Background"
          draggable={false}
          className={cls.background}
        />

        <div className="container">
          <div style={{ paddingTop: "1rem" }}></div>
          <Header />

          <h1 className={cls.title}>{title}</h1>
        </div>
      </section>
    </>
  );
};

export default Cover;
