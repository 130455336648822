/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link } from "react-router-dom";

import cls from "./Header.module.css";

import logo from "../../assets/logo.png";

const Header = () => {
  const [hamOpen, setHamOpen] = useState(false);

  const [hamburgerAnimation, setHumburgerAnimation] = useState({
    upper: cls.hamburgerBun,
    middle: cls.hamburgerLoaf,
    lower: cls.hamburgerBase,
  });

  const [showMenuData, setShowMenuData] = useState(cls.mobileNavigation);

  const [showNavLinks, setShowNavLinks] = useState(
    cls.hiddenMobileNavigationLinks
  );

  const clickedHandler = () => {
    if (!hamOpen) {
      setHumburgerAnimation({
        upper: cls.activeHamburgerBun,
        middle: cls.activeHamburgerLoaf,
        lower: cls.activeHamburgerBase,
      });
      setHamOpen(true);
      setShowNavLinks(cls.activeMobileNavigationLinks);
      setShowMenuData(cls.activeMobileNavigation);
    } else if (hamOpen) {
      setHumburgerAnimation({
        upper: cls.hamburgerBun,
        middle: cls.hamburgerLoaf,
        lower: cls.hamburgerBase,
      });
      setHamOpen(false);
      setShowNavLinks(cls.hiddenMobileNavigationLinks);
      setShowMenuData(cls.mobileNavigation);
    }
  };

  return (
    <>
      <div className={cls.header}>
        <img
          src={logo}
          className={cls.image_container}
          alt="Deepam Thermoplats"
          draggable={false}
        />

        <ul className={cls.link_container}>
          <li>
            <Link className={cls.links} to={"/"}>
              Home
            </Link>
          </li>
          <li>
            <Link className={cls.links} to={"/about"}>
              About
            </Link>
          </li>
          <li>
            <Link className={cls.links} to={"/gallery"}>
              Gallery
            </Link>
          </li>
          <li>
            <Link className={cls.links} to={"/services"}>
              Services
            </Link>
          </li>
          <li>
            <Link className={cls.links} to={"/contact"}>
              Contact
            </Link>
          </li>
          {/*<li className={cls.links}>*/}
          {/*  <Button*/}
          {/*    borderColor={"white"}*/}
          {/*    backgroundColor={"transparent"}*/}
          {/*    color={"white"}*/}
          {/*    hoveredBorderColor={"#124A2F"}*/}
          {/*    hoveredBackgroundColor={"white"}*/}
          {/*    hoveredColor={"#124A2F"}*/}
          {/*  >*/}
          {/*    Request Demo*/}
          {/*  </Button>*/}
          {/*</li>*/}
        </ul>

        <div className={cls.hamburger} onClick={clickedHandler}>
          <div className={hamburgerAnimation.upper}></div>
          <div className={hamburgerAnimation.middle}></div>
          <div className={hamburgerAnimation.lower}></div>
        </div>
      </div>

      <nav className={showMenuData}>
        <ul className={showNavLinks}>
          <li>
            <Link className={cls.links} to={"/"}>
              Home
            </Link>
          </li>
          <li>
            <Link className={cls.links} to={"/about"}>
              About
            </Link>
          </li>
          <li>
            <Link className={cls.links} to={"/gallery"}>
              Gallery
            </Link>
          </li>
          <li>
            <Link className={cls.links} to={"/services"}>
              Services
            </Link>
          </li>
          <li>
            <Link className={cls.links} to={"/contact"}>
              Contact
            </Link>
          </li>
          {/* <li className={cls.links}>
            <Button
              borderColor={"white"}
              backgroundColor={"transparent"}
              color={"white"}
              hoveredBorderColor={"#124A2F"}
              hoveredBackgroundColor={"white"}
              hoveredColor={"#124A2F"}
            >
              Request Demo
            </Button>
          </li> */}
        </ul>
      </nav>
    </>
  );
};

export default Header;
