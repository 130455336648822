import React from "react";

import cls from "./AboutUsCard.module.css";

const AboutUsCard = ({ src, heading }) => {
  return (
    <>
      <div className={cls.card}>
        <img src={src} alt={heading} className={cls.image} />
        <p className={cls.name}>{heading}</p>
      </div>
    </>
  );
};

export default AboutUsCard;
