import React from "react";

import Cover from "../Cover/Cover";
import FooterComp from "../FooterComp/FooterComp";
import Button from "../UI/Button/Button";

import cls from "./ContactUs.module.css";

const ContactUs = () => {
  return (
    <>
      <Cover title={"Contact Us"} />
      <section className={"container"}>
        <p className={cls.intro}>
          If you are interested in partnering with us for your next project or
          want to know more about our services, please feel free to reach out.
          We look forward to turning your dreams into green realities.
        </p>

        <form className={cls.form}>
          <div className={cls.inputContainer}>
            <input type={"text"} placeholder={"Your Name"} />
            <input type={"email"} placeholder={"Email Address"} />
          </div>

          <div className={cls.inputContainer}>
            <input type={"text"} placeholder={"Your Country"} />
            <input type={"number"} placeholder={"Phone Number"} />
          </div>

          <div className={cls.inputContainer}>
            <textarea rows={5} placeholder={"Your Message"} />
          </div>

          <div className={cls.buttonGroup}>
            <Button
              borderColor={"#213555"}
              backgroundColor={"transparent"}
              color={"#213555"}
              hoveredBorderColor={"white"}
              hoveredBackgroundColor={"#213555"}
              hoveredColor={"white"}
            >
              Call us
            </Button>

            <Button
              borderColor={"white"}
              backgroundColor={"#213555"}
              color={"white"}
              hoveredBorderColor={"#213555"}
              hoveredBackgroundColor={"transparent"}
              hoveredColor={"#213555"}
            >
              Whatsapp
            </Button>
          </div>
        </form>

        <FooterComp />
      </section>
    </>
  );
};

export default ContactUs;
