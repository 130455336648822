/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate } from "react-router-dom";

import Header from "../Header/Header";

import Button from "../UI/Button/Button";

import cls from "./LandingComp.module.css";
import { Link } from "react-router-dom";

import background from "../../assets/landing_background.png";
import headingImage from "../../assets/landing_heading.svg";

const LandingComp = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="container">
        <section className={cls.herosection}>
          <img
            src={background}
            alt="Background"
            className={cls.background}
            draggable={false}
          />

          <Header />

          <div className={cls.content}>
            <img
              src={headingImage}
              alt="Heading"
              className={cls.heading_image}
              draggable={false}
            />

            <div className={cls.button_container}>
              <span>
                <Button
                  borderColor={"white"}
                  backgroundColor={"transparent"}
                  color={"white"}
                  hoveredBorderColor={"black"}
                  hoveredBackgroundColor={"white"}
                  hoveredColor={"black"}
                  onClick={() => navigate("/about")}
                >
                  About us
                </Button>
              </span>
              <span>
                <Link className={cls.links} to={"/contact"}>
                  <Button
                    borderColor={"transparent"}
                    backgroundColor={"white"}
                    color={"#213555"}
                    hoveredBorderColor={"white"}
                    hoveredBackgroundColor={"transparent"}
                    hoveredColor={"white"}
                  >
                    Contact us
                  </Button>
                </Link>
              </span>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default LandingComp;
